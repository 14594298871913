import { motion } from "framer-motion";
import Footer from "../../components/footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import contentData from "../../datas/content-data.json";

const Contact = () => {
  return (
    <div className="h-min-screen w-full">
      <div className="w-full min-h-screen bg-banner-image bg-cover bg-fixed bg-center flex flex-row items-center justify-center relative">
        <motion.div
          animate={{ opacity: [0, 1] }}
          transition={{ times: [0, 1], ease: "linear" }}
          className="bg-black/50 w-2/3 h-56 flex flex-row items-center justify-center"
        >
          <h1 className="text-4xl md:text-5xl font-normal text-white uppercase text-center">
            Kontak Kami
          </h1>
        </motion.div>
      </div>
      <div
        id="about-us"
        className="w-full h-auto bg-stone-800 bg-fixed bg-cover bg-center p-8 md:p-20 flex flex-col justify-center items-center gap-8"
      >
        <h1 className="text-4xl md:text-5xl font-semibold uppercase text-white text-center">
          Temukan Kami!
        </h1>
        <div className="w-full h-auto flex flex-row justify-center items-start sm:items-center gap-x-4">
          <FontAwesomeIcon icon={faLocationPin} size="2x" color="#ffffff" />
          <p className="text-white text-left sm:text-center text-sm sm:text-lg">
            Desa Gempolan Kecamatan Pakel Kabupaten Tulungagung Jawa Timur
            Kabupaten Tulungagung, Jawa Timur 66273
          </p>
        </div>
        <div
          id="about-us"
          className="w-full h-auto flex flex-row flex-wrap justify-center  bg-stone-800"
        >
          <div className="w-full h-auto flex flex-col sm:flex-row justify-start">
            <div className="w-full">
              <iframe
                title="office address"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.8784751781495!2d111.85574063650186!3d-8.113854495749074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e791df6442c892f%3A0x67c3329ef427f040!2sJl.%20Desa%20Gempolan%2C%20Gempolan%2C%20Kec.%20Pakel%2C%20Kabupaten%20Tulungagung%2C%20Jawa%20Timur%2066273!5e0!3m2!1sid!2sid!4v1720187783941!5m2!1sid!2sid"
                width="100%"
                allowFullScreen="false"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="h-96 sm:h-[500px]"
              ></iframe>
            </div>
          </div>
        </div>
        <h5 className="text-white font-normal text-lg">
          Kami juga menerima konsultasi secara daring melalui media sosial kami
          :{" "}
        </h5>
        <div className="w-full lg:w-3/4 flex flex-col lg:flex-row gap-8 items-center justify-center">
          <a
            className="w-full lg:w-1/3 flex flex-row lg:items-center lg:justify-center border border-white rounded-sm gap-2 p-4"
            href={contentData["socmed-data"].whatsapp.url}
          >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" color="#ffffff" />
            <div className="flex flex-col justify-center items-start">
              <div className="text-white font-semibold">Whatsapp</div>
              <div className="text-white font-normal text-center text-sm sm:text-md">
                6285331383750
              </div>
            </div>
          </a>
          <a
            className="w-full lg:w-1/3 flex flex-row lg:items-center lg:justify-center border border-white rounded-sm gap-4 p-4"
            href={contentData["socmed-data"].facebook.url}
          >
            <FontAwesomeIcon icon={faFacebookF} size="2x" color="#ffffff" />
            <div className="flex flex-col justify-center items-start">
              <div className="text-white font-semibold">Facebook</div>
              <div className="text-white font-normal text-center text-sm sm:text-md">
                Doctor Interior Furniture
              </div>
            </div>
          </a>
          <a
            className="w-full lg:w-1/3 flex flex-row lg:items-center lg:justify-center border border-white rounded-sm gap-4 p-4"
            href={contentData["socmed-data"].gmail.url}
          >
            <FontAwesomeIcon
              icon={faEnvelopeOpenText}
              size="2x"
              color="#ffffff"
            />
            <div className="flex flex-col justify-center items-start">
              <div className="text-white font-semibold">Gmail</div>
              <div className="text-white font-normal text-center text-sm sm:text-md">
                dctrintfurniturta@gmail.com
              </div>
            </div>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
