import {
  faBuildingCircleCheck,
  faChevronDown,
  faClipboardCheck,
  faMoneyBillTransfer,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import contentData from "../../datas/content-data.json";
import { motion } from "framer-motion";
import Footer from "../../components/footer/footer";
import Marquee from "react-fast-marquee";

const HomeIndex = () => {
  return (
    <>
      <div className="w-full min-h-screen bg-banner-image bg-cover bg-fixed bg-center flex flex-row items-center justify-center">
        <motion.div
          animate={{ opacity: [0, 1] }}
          transition={{ times: [0, 1], ease: "linear" }}
          className="bg-black/75 w-3/4 min-h-56 flex flex-row items-center justify-center"
        >
          <h1 className="text-4xl md:text-5xl font-bn text-white uppercase text-center">
            Selamat datang pasien interior
          </h1>
        </motion.div>
        <a
          href="#about-us"
          className="absolute -bottom-8 h-16 w-16 bg-transparent rounded-full flex items-center justify-center border border-gray"
        >
          <FontAwesomeIcon icon={faChevronDown} size="1x" color="#ffffff" />
        </a>
      </div>
      <div
        id="about-us"
        className="w-full h-auto bg-stone-800 bg-fixed bg-cover bg-center p-12 md:p-16 flex flex-col items-center justify-center gap-y-8"
      >
        <h1 className="text-4xl md:text-5xl font-semibold uppercase text-white text-center">
          One-Stop Solution untuk Desain Interior Profesional.
        </h1>
        <div className="w-full h-auto flex flex-row gap-x-4 ">
          <p className="text-white text-center">
            {contentData["short-description"]}
          </p>
        </div>
        <div className="w-full flex sm:flex-nowrap sm:overflow-x-auto flex-col sm:flex-row xl:justify-center align-center gap-4 hidden-scrollbar">
          <div className="lg:w-full sm:min-w-84 min-w-64 flex flex-col items-center justify-center border border-white rounded-sm gap-y-2 p-4">
            <FontAwesomeIcon icon={faUserCheck} size="3x" color="#ffffff" />
            <div className="text-white font-semibold">Berpengalaman</div>
            <div className="text-white font-normal text-center text-sm sm:text-md">
              Telah melayani klien lebih dari 15 tahun
            </div>
          </div>
          <div className="lg:w-full sm:min-w-84 min-w-64 flex flex-col items-center justify-center border border-white rounded-sm gap-y-2 p-4">
            <FontAwesomeIcon
              icon={faBuildingCircleCheck}
              size="3x"
              color="#ffffff"
            />
            <div className="text-white font-semibold">Kualitas Terjamin</div>
            <div className="text-white font-normal text-center text-sm sm:text-md">
              Menyajikan kualitas interior terbaik dan tahan lama
            </div>
          </div>
          <div className="lg:w-full sm:min-w-84 min-w-64 flex flex-col items-center justify-center border border-white rounded-sm gap-y-2 p-4">
            <FontAwesomeIcon
              icon={faMoneyBillTransfer}
              size="3x"
              color="#ffffff"
            />
            <div className="text-white font-semibold">Harga Bersaing</div>
            <div className="text-white font-normal text-center text-sm sm:text-md">
              Desain interior berkualitas tinggi dengan harga yang bikin anda
              tersenyum
            </div>
          </div>
          <div className="lg:w-full sm:min-w-84 min-w-64 flex flex-col items-center justify-center border border-white rounded-sm gap-y-2 p-4">
            <FontAwesomeIcon
              icon={faClipboardCheck}
              size="3x"
              color="#ffffff"
            />
            <div className="text-white font-semibold">Garansi Resmi</div>
            <div className="text-white font-normal text-center text-sm sm:text-md">
              Gratis biaya perbaikan selama 1 tahun sejak tanggal pembelian.
              Syarat dan ketentuan berlaku.
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-auto bg-about-us-image-2 bg-fixed bg-cover bg-center bg-blend-hue py-16 px-4 sm:px-8 md:px-0 flex flex-col items-center justify-center gap-y-16">
        <div className="w-full md:w-5/6 h-auto bg-black/80 bg-fixded bg-cover bg-center p-4 sm:p-8 md:p-16 flex flex-col items-start justify-center gap-y-8">
          <h1 className="text-4xl md:text-5xl font-semibold uppercase text-white text-center">
            Pelanggan Kami
          </h1>
          <div className="w-full h-auto flex flex-row gap-x-4">
            <p className="text-white text-sm sm:text-lg">
              {contentData["mitra-kerja-content"]}
            </p>
          </div>
          <Marquee autoFill speed={100}>
            {contentData["mitraDatas"].map((data, key) => (
              <img
                alt={`mitra-${data.instanceName}`}
                src={data.instanceLogoUrl}
                className="rounded-full sm:w-32 sm:h-32 w-24 h-24 me-4 bg-white object-scale-down p-2"
              />
            ))}
          </Marquee>
        </div>
      </div>
      <div
        id="about-us"
        className="w-full h-auto bg-stone-800 bg-fixed bg-cover bg-center p-8 sm:p-12 md:p-16 flex flex-col items-center justify-center gap-y-8"
      >
        <h1 className="text-4xl md:text-5xl font-semibold uppercase text-white text-center">
          Masih ragu dengan kami?
        </h1>
        <div className="w-full h-auto flex flex-row gap-x-4 ">
          <p className="text-white sm:text-center text-justify">
            Jangan ragu jangan bimbang. Dengan pengalaman bertahun-tahun, kami
            telah mengasah kemampuan dalam menciptakan ruang yang fungsional dan
            estetis. Lihat portofolio kami untuk melihat bukti nyata dari
            keahlian kami.
          </p>
        </div>
        <a
          className="text-white font-light uppercase px-4 py-2 border-2 border-white rounded-md hover:bg-white hover:text-black transition"
          href="/portfolio"
        >
          Lihat Portofolio
        </a>
      </div>
      <Footer />
    </>
  );
};

export default HomeIndex;
