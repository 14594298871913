import { motion } from "framer-motion";
import contentData from "../../datas/content-data.json";
import Footer from "../../components/footer/footer";
import { useEffect, useState } from "react";
const Portfolio = () => {
  const [portfolioData, setPortfoliodata] = useState([]);

  useEffect(() => {
    const maxLength = 2;
    const tempData = [];

    for (let i = 0; i < contentData["portofolio-data"].length; i += maxLength) {
      tempData.push(contentData["portofolio-data"].slice(i, i + maxLength));
    }
    setPortfoliodata(tempData);
  }, []);

  return (
    <div className="h-min-screen w-full">
      <div className="w-full min-h-screen bg-banner-image bg-cover bg-fixed bg-center flex flex-row items-center justify-center relative">
        <motion.div
          animate={{ opacity: [0, 1] }}
          transition={{ times: [0, 1], ease: "linear" }}
          className="bg-black/75 w-3/4 h-56 flex flex-row items-center justify-center"
        >
          <h1 className="text-4xl md:text-5xl font-normal text-white uppercase text-center">
            Portofolio Kami
          </h1>
        </motion.div>
      </div>
      <div
        id="about-us"
        className="w-full h-auto bg-stone-800 bg-fixed bg-cover bg-center flex flex-col justify-center gap-8"
      >
        <div className="w-full h-auto bg-stone-800 bg-fixed bg-cover bg-center p-12 md:p-16 flex flex-col items-start justify-center gap-y-8">
          <h1 className="text-4xl md:text-5xl font-semibold uppercase text-white">
            Transformasi Ruang, Realisasi Impian.
          </h1>
          <div className="w-full h-auto flex flex-row gap-x-4 ">
            <p className="text-white text-justify">
              {contentData["services"].description}
            </p>
          </div>
        </div>
        <div
          id="our-experience"
          className="w-full min-h-screen bg-about-us-image bg-fixed bg-cover bg-center flex flex-col items-center justify-center"
        >
          {portfolioData.map((data, index) => (
            <div
              key={index}
              className={`flex items-center flex-wrap justify-center w-full ${
                index % 2 === 0 ? "flex-row" : "md:flex-row-reverse"
              }`}
            >
              {data.map((element, key) => (
                <div
                  key={key}
                  className={`w-full flex items-center justify-center bg-no-repeat bg-cover bg-top grayscale hover:grayscale-0 z-30 hover:z-10 transition h-96 ${
                    data.length === 1
                      ? "md:w-full"
                      : key % 2 === 0 && data.length === 2
                      ? "md:w-1/3"
                      : "md:w-2/3"
                  }`}
                  style={{
                    backgroundImage: `url(${element.image})`,
                  }}
                >
                  <h1 className="text-2xl font-normal uppercase text-white text-center p-8 bg-black/50 z-20 hover:before:opacity-100">
                    {element.title}
                  </h1>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
