import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import contentData from "../../datas/content-data.json";
import Footer from "../../components/footer/footer";

const AboutUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 640px)");

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    // Check initial screen size
    handleResize();

    // Add listener for screen size changes
    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 3000);

    return () => clearInterval(interval);
  }, [isMobile]);
  return (
    <div className="h-min-screen w-full">
      <div className="w-full min-h-screen bg-banner-image bg-cover bg-fixed bg-center flex flex-row items-center justify-center relative">
        <motion.div
          animate={{ opacity: [0, 1] }}
          transition={{ times: [0, 1], ease: "linear" }}
          className="bg-black/75 w-3/4 h-56 flex flex-row items-center justify-center"
        >
          <h1 className="text-4xl md:text-5xl font-normal text-white uppercase text-center">
            Tentang Kami
          </h1>
        </motion.div>
      </div>
      <div
        id="about-us"
        className="w-full h-auto bg-stone-800 bg-fixed bg-cover bg-center p-8 md:p-16 flex flex-col justify-center gap-8"
      >
        <h1 className="text-5xl md:text-6xl font-semibold uppercase text-white text-center">
          Doctor Interior
        </h1>
        <div className="w-full h-auto flex flex-row gap-x-4">
          <p className="text-white text-justify">{contentData["about-us"]}</p>
        </div>
        <div
          id="about-us"
          className="w-full h-auto flex flex-row flex-wrap justify-center  bg-stone-800"
        >
          <div className="w-full h-auto overflow-hidden">
            <div
              className="flex sm:overflow-x-scroll hidden-scrollbar transition-transform duration-300 ease-in-out sm:gap-4"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              <div className="lg:w-full sm:min-w-96 min-w-full h-56 sm:h-96 bg-about-us-image-1 bg-center bg-cover sm:border-2 sm:border-white sm:rounded-sm"></div>
              <div className="lg:w-full sm:min-w-96 min-w-full h-56 sm:h-96 bg-about-us-image-2 bg-center bg-cover sm:border-2 sm:border-white sm:rounded-sm"></div>
              <div className="lg:w-full sm:min-w-96 min-w-full h-56 sm:h-96 bg-about-us-image-3 bg-center bg-cover sm:border-2 sm:border-white sm:rounded-sm"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
